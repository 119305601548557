import React from "react";
import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import rem from "../utils/rem";
import Checkbox from "./form/Checkbox";
import Select from "./Select";
import Button from "./elements/Button";
import incidentStatusOptions from "../constants/incidentStatusOptions";
import incidentSeverityOptions from "../constants/incidentSeverityOptions";
import { isAbsIncident } from "../utils/IncidentUtil";

// TODO: Pull this out of TrackingAccuracyReportContainer
const DateInput = styled.input`
  text-align: center;
  line-height: ${rem(16)};
  border-radius: ${rem(4)};
  border: 1px solid #aeaeae;
  font-size: ${rem(13)};
  padding: ${rem(6)} ${rem(10)} ${rem(6)};
  cursor: pointer;
`;

const Wrapper = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  margin: ${rem(10)} 0;
`;

const InputWrapper = styled.div`
  text-align: ${(props) => props.align};
  visibility: ${(props) => (props.isHidden ? "hidden" : "visible")};
  padding: 10px;
  width: ${(props) => props.width};
`;

const Input = styled.input`
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 0.5px #d2d2d2;
  width: 100%;
  font-size: 14px;
  padding: 5px;
`;

const TextArea = styled.textarea`
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 0.5px #d2d2d2;
  width: 100%;
  height: 100px;
  font-size: 14px;
  padding: 5px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexItem = styled.div`
  flex: ${(props) => props.flex};
  align-self: flex-end;
`;

class IncidentForm extends React.Component {
  constructor(props) {
    super(props);

    const { previousIncident: incident = {}, incidentType } = props;
    const latestUpdate = _.first(incident.incidentUpdates) || {};

    this.state = {
      name: incident.name || "",
      description: incident.description || "",
      status: latestUpdate.status || "",
      severity: latestUpdate.severity || "",
      isNonActionable: false,
      incidentType: incidentType,
      homePlateUmpire: incident.homePlateUmpire || "",
    };

    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleSubmit() {
    const {
      name,
      status,
      description,
      severity,
      eta,
      incidentType,
      homePlateUmpire,
    } = this.state;
    this.props
      .onSubmit({
        name,
        status,
        description,
        severity,
        eta,
        incidentType,
        homePlateUmpire,
      })
      .then(this.props.afterSubmit);
  }

  render() {
    const { isEditing, incidentType } = this.props;
    const isAbs = isAbsIncident(incidentType);
    return (
      <Wrapper>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this._handleSubmit();
          }}
        >
          {!isEditing ? (
            <InputWrapper>
              <Label>Incident Name*</Label>
              <Input
                onChange={(e) => this.setState({ name: e.target.value })}
                value={this.state.name}
                required={true}
                type="text"
                placeholder="Incident Title"
              />
            </InputWrapper>
          ) : null}
          <InputWrapper>
            <FlexContainer>
              <Label>{isEditing ? "Updated " : ""}Incident Description*</Label>
              {!isEditing ? (
                <Checkbox
                  label="Non-Actionable Update"
                  isChecked={this.state.isNonActionable}
                  handleClick={(e) =>
                    this.setState({
                      isNonActionable: e.target.checked,
                      status: "Resolved",
                      eta: null,
                    })
                  }
                />
              ) : null}
            </FlexContainer>
            <TextArea
              value={this.state.description}
              onChange={(e) => this.setState({ description: e.target.value })}
              placeholder="Provide details about what's going on..."
            />
          </InputWrapper>
          <FlexContainer>
            <FlexItem flex="2">
              <FlexContainer>
                <FlexItem flex="1">
                  <InputWrapper>
                    <Label>Status</Label>
                    <Select
                      name="status-input"
                      simpleValue
                      disabled={this.state.isNonActionable}
                      onChange={(status) => this.setState({ status })}
                      value={this.state.status}
                      placeholder="Status"
                      clearable={false}
                      options={incidentStatusOptions}
                    />
                  </InputWrapper>
                </FlexItem>
                <FlexItem flex="1">
                  <InputWrapper>
                    <Label>Severity</Label>
                    <Select
                      name="severity-input"
                      simpleValue
                      onChange={(severity) => this.setState({ severity })}
                      value={this.state.severity}
                      placeholder="Severity"
                      clearable={false}
                      options={incidentSeverityOptions}
                    />
                  </InputWrapper>
                </FlexItem>
                {isAbs ? (
                  <FlexItem flex="1">
                    <InputWrapper>
                      <Label>Home Plate Umpire</Label>
                      <Input
                        onChange={(e) =>
                          this.setState({ homePlateUmpire: e.target.value })
                        }
                        value={this.state.homePlateUmpire}
                        type="text"
                        placeholder="Home Plate Umpire Title"
                      />
                    </InputWrapper>
                  </FlexItem>
                ) : null}
                <FlexItem flex="1">
                  <InputWrapper isHidden={this.state.isNonActionable}>
                    <Label>ETA (in Eastern)</Label>
                    <DatePicker
                      showTimeSelect
                      selected={this.state.eta}
                      timeFormat="LT"
                      timeIntervals={15}
                      onChange={(eta) => this.setState({ eta })}
                      minDate={moment()}
                      dateFormat="LLL"
                      customInput={<DateInput />}
                      dropdownMode="select"
                    />
                  </InputWrapper>
                </FlexItem>
              </FlexContainer>
            </FlexItem>
            <FlexItem flex="1">
              <InputWrapper align="right">
                <Button isPrimary>
                  {isEditing ? "Update" : "Create Incident"}
                </Button>
              </InputWrapper>
            </FlexItem>
          </FlexContainer>
        </form>
      </Wrapper>
    );
  }
}

IncidentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  gamePk: PropTypes.string,
  date: PropTypes.string,
  isSystemWide: PropTypes.bool,
  previousIncident: PropTypes.object,
};

export default IncidentForm;

import _ from "lodash";
import { extendObservable, action, computed } from "mobx";
import { getMatchupSlug } from "../utils/game";
import GameStatuses from "../constants/GameStatuses";
import { RouteConstants } from "../constants/RouteConstants";
import IncidentTypes from "../constants/IncidentTypes";

class VeracityStore {
  constructor(veracityApi, routerStore, authStore) {
    this.veracityApi = veracityApi;
    this.routerStore = routerStore;
    this.authStore = authStore;

    extendObservable(this, {
      incidentsOverview: [],
      singleIncident: {},
      newIncidentMetadata: {},
      gameStatuses: computed(() => {
        return _.chain(this.incidentsOverview)
          .groupBy("gamePk")
          .mapValues((incidents) => {
            if (
              _.every(incidents, (incident) => {
                return (
                  _.first(incident?.incidentUpdates)?.status === "Resolved"
                );
              })
            ) {
              return GameStatuses.OPEN_INCIDENTS_RESOLVED;
            } else {
              return GameStatuses.OPEN_INCIDENTS;
            }
          })
          .value();
      }),
      fetchIncidents: action((incidentType, date) => {
        this.incidentsOverview = [];
        console.log(date);
        this.veracityApi.fetchIncidents(incidentType, date).then((response) => {
          this.incidentsOverview = response.data;
        });
      }),
      fetchIncident: action((incidentId) => {
        return this.veracityApi.fetchIncident(incidentId).then((response) => {
          this.singleIncident = response.data[0];
        });
      }),
      createIncident: action(
        ({ name, status, description, severity, eta, homePlateUmpire }) => {
          const {
            gamePk,
            date: incidentDate,
            isSystemWide,
            incidentType,
          } = this.newIncidentMetadata;
          const incidentModel = {
            name,
            gamePk,
            statsapiUsername: "test",
            incidentUpdates: [
              {
                status,
                description,
                severity,
                statsapiUsername: "test",
                eta: eta && eta.format(),
              },
            ],
            incidentDate,
            systemWide: isSystemWide,
            incidentType,
            homePlateUmpire,
          };
          return this.veracityApi.createIncident(incidentModel);
        },
      ),
      updateSingleIncidentName: action((name) => {
        const incidentToPut = Object.assign({}, this.singleIncident, { name });
        return veracityApi
          .updateIncident(this.singleIncident.id, incidentToPut)
          .then((data) => {
            const updatedIncident = data.data[0];
            this.singleIncident = _.merge(this.singleIncident, updatedIncident);
          });
      }),
      createIncidentUpdate: action(({ status, description, severity, eta }) => {
        const incidentUpdateModel = {
          status,
          description,
          severity,
          statsapiUsername: authStore.username,
          eta: eta && eta.format(),
        };

        return this.veracityApi.createIncidentUpdate(
          this.singleIncident.id,
          incidentUpdateModel,
        );
      }),
      updateIncidentUpdateName: action((incidentUpdate, description) => {
        const incidentUpdateToPut = Object.assign({}, incidentUpdate, {
          description,
        });
        const { id, incidentId } = incidentUpdate;
        return veracityApi
          .updateIncidentUpdate(incidentId, id, incidentUpdateToPut)
          .then((_) => this.fetchIncident(incidentId));
      }),
      updateNewIncidentMetadata: action((incidentType, game, date, toRoute) => {
        const { gamePk } = game;
        const gameSlug = getMatchupSlug(game);

        console.log(date);

        this.newIncidentMetadata = {
          gamePk,
          gameSlug,
          date,
          incidentType,
          toRoute,
        };
      }),
      updateNewSystemIncidentMetadata: action((date) => {
        this.newIncidentMetadata = {
          date,
          isSystemWide: true,
          incidentType: IncidentTypes.SYSTEM,
        };
      }),
      deleteIncident: action((incidentId) => {
        return veracityApi
          .deleteIncident(incidentId)
          .then((_) =>
            this.routerStore.history.length > 1
              ? this.routerStore.history.goBack()
              : this.routerStore.history.push("/incidents"),
          );
      }),
      deleteIncidentUpdate: action((incidentUpdate) => {
        const { id, incidentId } = incidentUpdate;
        return veracityApi
          .deleteIncidentUpdate(incidentId, id)
          .then((_) => this.fetchIncident(incidentId));
      }),
    });
  }

  transitionAfterSave = () => {
    const toRoute = this.newIncidentMetadata.toRoute
      ? this.newIncidentMetadata.toRoute
      : RouteConstants.BASE;
    this.routerStore.history.push({
      pathname: toRoute,
      search: this.newIncidentMetadata.date
        ? `?date=${this.newIncidentMetadata.date}`
        : "",
    });
  };

  createGameNote = (gameNote) => {
    return this.veracityApi.createGameNote(gameNote);
  };

  updateGameNote = (gameNote) => {
    return this.veracityApi.updateGameNote(gameNote);
  };
}

export default VeracityStore;

import React from "react";
import { inject, observer } from "mobx-react";
import IncidentTypes from "../../constants/IncidentTypes";
import BaseMLBTrackingAccuracyReportContainer from "../BaseMLBTrackingAccuracyReportContainer";
import NPBTrackingAccuracyReportTable from "../../components/accuracyReports/NPBTrackingAccuracyReportTable";

class NPBTrackingAccuracyReportContainer extends React.Component {
  render() {
    const rootStore = this.props.rootStore;
    const description =
      "A real-time pitch-by-pitch data report that monitors the overall quality of data and video systems used for NPB games.";

    const reportTable = (
      <NPBTrackingAccuracyReportTable
        data={rootStore.NPBGameActionStore.trackingAccuracyReportSource.data}
        gameStatuses={rootStore.veracityStore.gameStatuses}
        transitionToCreateIncident={
          rootStore.NPBGameActionStore.trackingAccuracyReportSource
            .transitionToCreateIncident
        }
        canCreateIncident={true}
        groupsToShow={rootStore.tableControlsStore.state}
      />
    );
    return (
      <BaseMLBTrackingAccuracyReportContainer
        header="NPB Data Report"
        description={description}
        reportSource={rootStore.NPBGameActionStore.trackingAccuracyReportSource}
        reportTable={reportTable}
        incidentTypesToFetch={[IncidentTypes.NPB, IncidentTypes.SYSTEM]}
        showSystemIncidentButton={rootStore.authStore.hasIncidentEdit}
      />
    );
  }
}

export default inject("rootStore")(
  observer(NPBTrackingAccuracyReportContainer),
);

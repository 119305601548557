import IncidentTypes from "../constants/IncidentTypes";
import { RouteConstants } from "../constants/RouteConstants";
import TrackingAccuracyReportStore from "./trackingAccuracyReportStore";

class NPBTrackingAccuracyReportStore extends TrackingAccuracyReportStore {
  fetchTrackingAccuracyReport() {
    return this.veracityApi.fetchNPBTrackingAccuracyReport(this.get("date"));
  }

  cancelTrackingAccuracyReport() {
    return this.veracityApi.cancelNPBTrackingAccuracyReport();
  }

  isTrackingTabActive() {
    return this.routerStore.isNPBTrackingAccuracyReportTab;
  }

  updateNewIncidentMetadata(game) {
    return this.veracityStore.updateNewIncidentMetadata(
      IncidentTypes.NPB,
      game,
      this.get("date"),
      RouteConstants.NPB_TRACKING,
    );
  }
}

export default NPBTrackingAccuracyReportStore;
